<template>
  <modal v-model="updateCardsDialogOpen">
    <header class="dialog-header">
      <typography type="h3">Confirm order</typography>
    </header>

    <v-layout justify-center>
      <v-flex sm10>
        <div class="price-summary">
          <div class="prices">
            <price-row :quantity="numCards" label="Number of cards" />
          </div>
        </div>
      </v-flex>
    </v-layout>

    <v-layout justify-center>
      <v-flex sm12>
        <typography light no-margin>
          <p>Your card/s will be delivered in <i>5 working days</i> after your {{ brand }} application has been approved.</p>
        </typography>
      </v-flex>
    </v-layout>

    <v-layout justify-center>
      <v-flex sm12>
        <typography light no-margin>
           <p>A confirmation message will be sent to your email address.</p>
           <p class="confirm-important-msg">IMPORTANT: You must contact Z if your cards have not arrived in 5 working days to avoid being held liable for any fraudulent activity on your card/s during the delivery period.</p>
         </typography>
        </v-flex>
      </v-layout>

    <round-button slot="left-button" outline @click="onCancel">Cancel</round-button>
    <round-button slot="right-button" :loading="submitCardChangesInProgress" @click="onComplete">Complete</round-button>
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import Autocomplete from '@/components/molecules/Autocomplete.vue';
import PriceRow from '@/components/molecules/PriceRow.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import { mapState, mapGetters } from 'vuex';
import { CARD_PREFERENCES_COMPLETED } from '@/constants/routes';
import { BRAND } from '@/constants/form';

export default {
  components: {
    Modal,
    Typography,
    RoundButton,
    Autocomplete,
    TextInput,
    PriceRow
  },
  computed: {
    updateCardsDialogOpen: {
      get() {
        return this.$store.state.ui.updateCardsDialogOpen;
      },
      set(value) {
        this.$store.dispatch('ui/toggleUpdateCardsDialogOpen', value);
      }
    },
    ...mapState({
      submitCardChangesInProgress: state =>
        state.progress.submitCardChanges.inProgress,
      sectionComplete: state => state.cardPreferences.sectionComplete
    }),
    ...mapGetters({
      numCards: 'cardPreferences/numCards'
    }),
    brand: () => BRAND
  },
  methods: {
    async onComplete() {
      await this.$store.dispatch('cardPreferences/submitCardChanges');
      if (this.sectionComplete) {
        this.$router.push(CARD_PREFERENCES_COMPLETED);
      }
    },
    onCancel() {
      this.$store.dispatch('ui/toggleUpdateCardsDialogOpen', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}

.price-summary {
  margin-bottom: rem(50px);
}

.prices {
  text-align: left;
  margin-bottom: rem(5px);
}

.price-note {
  color: $note-color;
}

.confirm-important-msg {
  font-style: italic;
  font-size: rem(14px);
  line-height: rem(20px);
  margin-bottom: rem(10px);

  @include md {
    font-size: rem(15px);
  }
}
</style>
